@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: berkeley;
  src: url("/fonts/BerkeleyMono-Regular.otf") format("opentype");
}

@font-face {
  font-family: berkeley-bold;
  src: url("/fonts/BerkeleyMono-Bold.otf") format("truetype");
}

@font-face {
  font-family: space;
  src: url("/fonts/SpaceGrotesk-Regular.ttf") format("truetype");
}

@font-face {
  font-family: space-bold;
  src: url("/fonts/SpaceGrotesk-SemiBold.ttf") format("truetype");
}

* {
  font-family: space, monospace;
}

body {
  color: white;
  background: black;
}

.messageContainer {
  overflow-y: scroll; /* Always show scrollbar space */
  scrollbar-width: thin; /* For Firefox */
  padding-right: 2px; /* Adjust this value to match your scrollbar width */
  margin-right: -2px;
}

/* For Webkit browsers like Chrome and Safari */
.messageContainer::-webkit-scrollbar {
  width: 6px; /* Adjust width for scrollbar */
}

.messageContainer::-webkit-scrollbar-thumb {
  background-color: transparent; /* Hide scrollbar by default */
}

.messageContainer:hover::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Show scrollbar on hover */
}

/* not mobile devices */
@media (min-width: 768px) {
  .messageContainer {
    padding-right: 10px; /* Adjust this value to match your scrollbar width */
    margin-right: -10px;
  }
}

.wallet-adapter-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1a1f2e;
}

.wallet-adapter-button:not([disabled]):hover {
  background-color: #303542;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.group:hover .arrow-bounce {
  animation: bounce 1s infinite;
}
